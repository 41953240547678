import createApi from "../utils/axiosInstance";

// Fetch particular user
export const fetchUser = async (userId) => {
  try {
    const api = createApi(false); // Token required
    const response = await api.get("/user/getUserById/" + userId);
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Complete user profile
export const completeProfile = async (userData, userId) => {
  try {
    const api = createApi(false);
    const response = await api.post(`/user/editProfile/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Update user password
export const updatePassword = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/change-password`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Changing profile password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Get user type
export const getUserType = async () => {
  try {
    const api = createApi(false);
    const response = await api.get("/user/getUserTypeList");
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user type failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};
