import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import { VisibilityOff, Visibility, ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  IconButton,
  InputAdornment
} from "@mui/material";
import { updatePassword } from "../../api/userApi";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));

const UpdatePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const location = useLocation();
  let { email } = location.state || { email: "" };
  const [formErrors, setFormErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:590px)");

  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleClickShowPassword = (name) =>
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate the input fields
    if (name === "newPassword" || name === "confirmPassword") {
      if (!validatePassword(value)) {
        setFormErrors({
          ...formErrors,
          [name]:
            "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, and 1 digit."
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
      if (name === "confirmPassword" && value !== formData.newPassword) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords do not match."
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [name]: value.trim() === "" ? "This field is required." : ""
      });
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (
      Object.values(formErrors).some((error) => error) ||
      !formData.currentPassword
    ) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }

    const mainData = new FormData();
    mainData.append("currentPassword", formData.currentPassword);
    mainData.append("newPassword", formData.newPassword);

    try {
      const data = await updatePassword({
        email,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword
      });
      if (data) {
        setSnackbarMessage("Profile updated successfully.");
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Profile update failed.");
        setOpenSnackbar(true);
      }
    } catch ({ error }) {
      console.log(error);
      setSnackbarMessage(error);
      setOpenSnackbar(true);
    }
  };

  // Disable the button if there are errors or any required fields are empty
  const isFormValid =
    formData.currentPassword &&
    formData.newPassword &&
    formData.confirmPassword &&
    !Object.values(formErrors).some((error) => error);

  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap={3} p="32px 0 0 20px">
        <IconButton onClick={() => navigate("/profile")}>
          <ChevronLeft
            sx={{ height: "35px", width: "35px", fill: "white" }}
            height={35}
            width={35}
          />
        </IconButton>
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="24px"
        >
          Change Password
        </Typography>

        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          width: { xs: "100%", md: "60%" },
          paddingTop: { xs: 2, md: 4 },
          paddingRight: { xs: 2, md: 13, lg: 20 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 10 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <Box
          component={"form"}
          sx={{
            width: { xs: "100%", md: "50%" },
            ...(matches && { marginBottom: { xs: 9 } })
          }}
          onSubmit={handleUpdatePassword}
        >
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">
              Current Password
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword?.currentPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("currentPassword")}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      sx={{ color: "#6B7280" }}
                      edge="end"
                    >
                      {showPassword?.currentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="currentPassword"
              placeholder="Enter current password"
              value={formData.currentPassword}
              onChange={handleInputChange}
              error={!!formErrors.currentPassword}
              helperText={formErrors.currentPassword}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">New Password</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword?.newPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("newPassword")}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      sx={{ color: "#6B7280" }}
                      edge="end"
                    >
                      {showPassword?.newPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="newPassword"
              placeholder="Enter New Password"
              value={formData.newPassword}
              onChange={handleInputChange}
              error={formErrors.newPassword}
              helperText={formErrors.newPassword}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">
              Re-enter New Password
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword?.confirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confirmPassword")}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      sx={{ color: "#6B7280" }}
                      edge="end"
                    >
                      {showPassword?.confirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="confirmPassword"
              placeholder="Re-enter New Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              error={formErrors.confirmPassword}
              helperText={formErrors.confirmPassword}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid}
            color="primary"
            sx={{
              marginTop: 3.1,
              backgroundColor: "#38B65B",
              cursor: "pointer",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": {
                backgroundColor: "#D7F0DE",
                color: "#9CDBAD"
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
