import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect } from "react";
import { Add } from "@mui/icons-material";
import { ReactComponent as LikeIcon } from "../../assets/like.svg";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { ReactComponent as ProteinIcon } from "../../assets/protein.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import AddMod from "../Modals/AddMOD";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentDateMeal } from "../../features/mod/modSlice";
import dayjs from "dayjs";
import { selectMealData } from "../../features/mod/modSelectors";
import { calculateTotalQuantity } from "../../utils/getTotal";
import getInitials from "../../utils/getInitials";

const Mod = () => {
  const [isAddingMeal, setIsAddingMeal] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const currentMeal = useSelector(selectMealData);

  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints

  useEffect(() => {
    dispatch(getCurrentDateMeal({ currentDate: dayjs().format("YYYY-MM-DD") }));
  }, [dispatch]);

  const totalFruitsVeggies = calculateTotalQuantity(
    currentMeal?.fruitsVeggies || []
  );
  const totalOtherIngredients = calculateTotalQuantity(
    currentMeal?.otherIngredients || []
  );
  const totalProtein = calculateTotalQuantity(currentMeal?.protein || []);
  return (
    <Box
      pb={10}
      sx={{
        padding: { sm: "40px 32px", xs: "0 0 115px 0" }
      }}
    >
      {isAddingMeal ? (
        <AddMod isAddingMeal={isAddingMeal} setIsAddingMeal={setIsAddingMeal} />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Box
              sx={{
                ...(isMobile && {
                  width: "100%",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  background: "#1F2937",
                  color: "#FCFCFC" // Apply color to the entire box in mobile view
                })
              }}
            >
              <Box display="flex" alignItems="center" gap="10px">
                {isMobile && (
                  <Box>
                    <Box
                      component="img"
                      height={54}
                      borderRadius="50%"
                      width={54}
                      border="1px solid #38B65B"
                      src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION}/${user?.profilePic}`}
                      alt="avatar"
                    />
                  </Box>
                )}
                <Box>
                  <Typography
                    fontSize={isMobile ? "14px" : "30px"}
                    fontWeight={400}
                    color={"#9CA3AF"}
                  >
                    Welcome back {user?.displayName || user?.fullName}
                  </Typography>
                  <Typography
                    fontSize={isMobile ? "18px" : "24px"}
                    fontWeight={isMobile ? 600 : 500}
                  >
                    {new Date().toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })}
                  </Typography>
                </Box>
              </Box>
              {isMobile && (
                <Box>
                  <Button
                    variant="outlined"
                    // onClick={() => goBack(false)}
                    sx={{
                      textTransform: "none",
                      color: "#38B65B",
                      borderColor: "#38B65B",
                      mt: 1, // Reduced margin top
                      gap: "8px",
                      borderRadius: "4px",
                      alignSelf: "flex-end" // Align button to the end horizontally
                    }}
                  >
                    Refer a friend
                  </Button>
                </Box>
              )}
            </Box>

            {/* For Web view, Submit MOD Button remains here */}
            {!isMobile && (
              <Button
                onClick={() => setIsAddingMeal(!isAddingMeal)}
                sx={{
                  textTransform: "none",
                  background: "#38B65B",
                  color: "#fcfcfc",
                  height: "40px",
                  padding: "12px 24px",
                  gap: "8px",
                  borderRadius: "4px"
                }}
              >
                <Add /> Submit MOD
              </Button>
            )}
          </Box>

          {currentMeal?._id ? (
            <Box
              mt={isMobile ? 2 : 3}
              gap={isMobile ? 2 : 4}
              display="flex"
              padding={isMobile ? "16px" : ""}
              flexDirection={isMobile ? "column" : "row"}
            >
              <Box width={isMobile ? "100%" : "50%"}>
                <Box
                  component="img"
                  alt="meal"
                  src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION}/${currentMeal?.mealImage}`}
                  height={isMobile ? 200 : 355}
                  width={"100%"}
                  sx={isMobile ? { padding: "24px 16px" } : {}}
                  borderRadius={10}
                />
              </Box>

              <Box width="100%">
                <Typography
                  color="#FCFCFC"
                  fontSize={isMobile ? "28px" : "36px"}
                  fontWeight={isMobile ? 600 : 500}
                >
                  {currentMeal?.title}
                </Typography>
                <Box
                  display={isMobile ? "flex" : "block"}
                  alignItems={isMobile ? "center" : "flex-start"}
                  justifyContent={isMobile ? "space-between" : "flex-start"}
                >
                  <Box display="flex" gap="10px" alignItems="center">
                    {currentMeal?.userData?.[0]?.profilePic ? (
                      <Box
                        component="img"
                        height={40}
                        borderRadius="50%"
                        border="1px solid #38B65B"
                        width={40}
                        src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION}/${currentMeal?.userData?.[0]?.profilePic}`}
                        alt="avatar"
                      />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }}>
                        {getInitials(
                          currentMeal?.adminUserData?.[0]?.name ||
                            currentMeal?.userData?.[0]?.fullName
                        )}
                      </Avatar>
                    )}{" "}
                    <Typography
                      color="#FCFCFC"
                      fontSize="14px"
                      fontWeight={400}
                    >
                      {currentMeal?.adminUserData?.[0]?.name ||
                        currentMeal?.userData?.[0]?.fullName}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: { md: 3 } }} display="flex" gap="10px">
                    <IconButton
                      sx={{
                        padding: 2,
                        height: "32px",
                        borderRadius: "4px",
                        border: "1px solid #fcfcfc"
                      }}
                    >
                      <LikeIcon />
                      <Typography color="#fcfcfc" ml={1}>
                        105
                      </Typography>
                    </IconButton>
                    <IconButton
                      sx={{
                        height: "32px",
                        borderRadius: "4px",
                        border: "1px solid #fcfcfc"
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Box
                  mt={3}
                  height={64}
                  borderRadius={2}
                  display="flex"
                  gap="10px"
                >
                  <Box
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"left"}
                    display="flex"
                    gap={2}
                    sx={{
                      background: "#333333",
                      padding: "8px 16px",
                      color: "#9CA3AF"
                    }}
                    borderRadius="8px"
                  >
                    <AppleIcon />
                    <Box>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color="#8E8E8E"
                      >
                        800g
                      </Typography>
                      <Typography
                        fontSize="24px"
                        fontWeight={500}
                        color="#F6541C"
                      >
                        {totalFruitsVeggies + totalOtherIngredients}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"left"}
                    display="flex"
                    gap={2}
                    sx={{
                      background: "#333333",
                      padding: "8px 16px",
                      color: "#9CA3AF"
                    }}
                    borderRadius="8px"
                  >
                    <ProteinIcon />
                    <Box>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color="#8E8E8E"
                      >
                        Protein
                      </Typography>
                      <Typography
                        fontSize="24px"
                        fontWeight={500}
                        color="#2C75C5"
                      >
                        {totalProtein}g
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  width={"100%"}
                  sx={{
                    background: "#333333",
                    padding: "12px 16px",
                    color: "#9CA3AF",
                    mt: 3,
                    borderRadius: "8px"
                  }}
                >
                  <Typography color="#FCFCFC" fontSize="16px" fontWeight={600}>
                    Ingredients
                  </Typography>
                  <ul
                    style={{
                      paddingLeft: "18px",
                      margin: 0,
                      marginTop: 8,
                      color: "#D1D5DB"
                    }}
                  >
                    {currentMeal?.fruitsVeggies?.map((item) => (
                      <li key={item._id}>
                        <Typography variant="body1">
                          {item.quantity} {item.unit} {item.name}
                        </Typography>
                      </li>
                    ))}
                    {currentMeal?.otherIngredients?.map((item) => (
                      <li key={item._id}>
                        <Typography variant="body1">
                          {item.quantity} {item.unit} {item.name}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>

                <Box
                  width={"100%"}
                  sx={{
                    background: "#333333",
                    padding: "12px 16px",
                    mt: 3,
                    borderRadius: "8px"
                  }}
                >
                  <Typography color="#FCFCFC" fontSize="16px" fontWeight={600}>
                    Details
                  </Typography>
                  <Typography mt={1} variant="body1" color="#D1D5DB">
                    {currentMeal?.generalText}
                  </Typography>
                </Box>

                {/* For Mobile, Submit MOD Button at the end */}
                {isMobile && (
                  <Box display="flex" justifyContent="end">
                    <Button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsAddingMeal(!isAddingMeal);
                      }}
                      sx={{
                        textTransform: "none",
                        background: "#38B65B",
                        color: "#fcfcfc",
                        height: "40px",
                        padding: "12px 24px",
                        gap: "8px",
                        borderRadius: "4px",
                        mt: 3 // Make sure it has some margin on top
                      }}
                    >
                      <Add /> Submit MOD
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box mt={5}>No Meal Scheduled for today</Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Mod;
