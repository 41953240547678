import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../features/auth/authSlice";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
  VisibilityOff,
  Visibility
} from "@mui/icons-material";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  FormControl,
  InputLabel,
  styled,
  Select,
  MenuItem,
  Alert,
  useMediaQuery,
  IconButton,
  InputAdornment
} from "@mui/material";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { getUserType } from "../../api/userApi";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));

const Register = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    displayName: "",
    email: "",
    password: "",
    profilePic: null,
    bio: "",
    url: "",
    isJoiningThroughSource: false,
    userType: "Individual",
    referenceId: ""
  });
  const [availableOptions, setAvailableOptions] = useState({});
  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    email: false,
    password: false,
    bio: false,
    url: false
  });
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validateUrl = (url) =>
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
      url
    );
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else if (name === "bio") {
      setFormErrors({
        ...formErrors,
        bio: !(value.length < 50)
      });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleBlurValidation = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    }
    if (name === "url") {
      setFormErrors({ ...formErrors, url: !validateUrl(value) });
    }
  };

  useEffect(() => {
    if (formData.isJoiningThroughSource) {
      setFormData((prevState) => ({
        ...prevState,
        referenceId: ""
      }));
    }
  }, [formData.userType, formData.isJoiningThroughSource]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };

  const getUserTypes = async () => {
    try {
      const data = await getUserType();
      return data;
    } catch (error) {
      console.error(
        "Get user types failed:",
        error.response ? error.response.data : error.message
      );
      throw error.response ? error.response.data : error;
    }
  };

  useEffect(() => {
    getUserTypes()
      .then((data) => {
        if (data.usersTypeList) {
          setAvailableOptions(data.usersTypeList);
        }
      })
      .catch(() => setAvailableOptions([]));
  }, []);

  const handleRegister = async (e) => {
    const mainData = new FormData();
    mainData.append("fullName", formData.fullName);
    mainData.append("displayName", formData.displayName);
    mainData.append("email", formData.email);
    mainData.append("password", formData.password);
    mainData.append("bio", formData.bio);
    mainData.append("url", formData.url);
    mainData.append("referenceId", formData.referenceId || "");
    mainData.append("userType", formData.userType || "Individual");
    if (formData.profilePic) {
      mainData.append("profilePic", formData.profilePic);
    }
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    try {
      const { payload } = await dispatch(registerUser(mainData));
      if (!payload.error) navigate("/");
      else {
        setSnackbarMessage(payload.error || "Registration failed.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Registration failed.");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const scrollableElement = document.documentElement; // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="auto"
      height="100vh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100vh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          margin: "20px 0 0 0"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 2, md: 8, lg: 10 },
          paddingRight: { xs: 2, md: 13, lg: 20 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 10 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="30px"
          fontWeight="bold"
          gutterBottom
        >
          Create Account
        </Typography>
        {/* <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          color="#6B7280"
          fontSize="16px"
          gutterBottom
        >
          Sign up with email
        </Typography> */}
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          component={"form"}
          sx={{
            ...(matches && { marginBottom: { xs: 9 } })
          }}
          onSubmit={handleRegister}
        >
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              ...(matches && { justifyContent: "center" })
            }}
            display="flex"
            gap="50px"
            alignItems="center"
          >
            {formData.profilePic ? (
              <Avatar
                alt="Profile Pic"
                src={URL.createObjectURL(formData.profilePic)}
                sx={{
                  width: 76,
                  height: 76,
                  marginTop: 2,
                  border: "1px solid #38B65B"
                }}
              />
            ) : (
              <label htmlFor="profile-pic-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="default"
                  style={{
                    borderRadius: "48%",
                    backgroundColor: "#ccc",
                    width: 76,
                    height: 76,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    cursor: "pointer"
                  }}
                >
                  <CameraIcon style={{ width: 24, height: 24 }} />
                </Button>
              </label>
            )}
          </Box>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="fullName">Name *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="fullName"
              placeholder="Enter your Full name"
              value={formData.fullName}
              onChange={handleInputChange}
              error={formErrors.fullName}
              helperText={formErrors.fullName && "Name is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="displayName">
              Username (This will be displayed in app) *
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="displayName"
              sx={{ marginTop: "20px" }}
              placeholder="Enter your Username"
              value={formData.displayName}
              onChange={handleInputChange}
              error={formErrors.displayName}
              helperText={formErrors.displayName && "Username is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="bio">
              Bio (50 character text)
            </StyledInputLabel>
            <StyledTextField
              sx={{
                "& .MuiInputBase-root": {
                  color: "#FCFCFC"
                }
              }}
              fullWidth
              variant="outlined"
              name="bio"
              placeholder="Enter your bio (optional)"
              value={formData.bio}
              multiline
              rows={4}
              onChange={handleInputChange}
              margin="normal"
              error={formErrors.bio}
              helperText={
                formErrors.bio && "Bio should be less than 50 characters"
              }
            />
            <Typography
              variant="caption"
              sx={{
                color: formData.bio.length > 50 ? "red" : "#D1D5DB",
                position: "absolute",
                fontSize: "15px",
                right: 10,
                bottom: 10
              }}
            >
              {formData.bio.length}/50
            </Typography>
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">Email Address *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleBlurValidation}
              error={formErrors.email}
              helperText={formErrors.email && "Enter a valid email address"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">Password *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      sx={{ color: "#FCFCFC" }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
              error={formErrors.password}
              helperText={
                formErrors.password &&
                "Password must be 8 characters long: Contains 1 upper, 1 lower case and 1 digit"
              }
              margin="normal"
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="url">
              URL (link to their website, social media, etc.)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              sx={{ marginTop: "20px" }}
              variant="outlined"
              name="url"
              placeholder="Enter URL (optional)"
              value={formData.url}
              onChange={handleInputChange}
              // onBlur={handleBlurValidation}
              // error={formErrors.url}
              // helperText={formErrors.url && "Enter a valid URL"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <FormControlLabel
            control={
              <Checkbox
                name="isJoiningThroughSource"
                checked={formData.isJoiningThroughSource}
                onChange={handleInputChange}
                icon={
                  <CheckBoxOutlineBlank
                    sx={{ color: "#D1D5DB" }} // Gray color when unchecked
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    sx={{
                      color: "#FCFCFC",
                      borderRadius: "4px"
                    }}
                  />
                }
                sx={{
                  "&.Mui-checked": {
                    border: "none",
                    color: "#38B65B" // Green border when checked
                  }
                }}
              />
            }
            label="Are you joining through Coach / Gym / Company"
            sx={{
              color: "#D1D5DB",
              fontWeight: "bold",
              fontSize: "14px",
              marginBottom: "16px"
            }}
          />

          {formData.isJoiningThroughSource &&
          Object.values(availableOptions)?.length > 0 ? (
            <>
              {" "}
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: 30 }}
              >
                <InputLabel
                  id="source-of-joining-label"
                  sx={{ color: "#FCFCFC" }}
                >
                  Source of joining
                </InputLabel>
                <Select
                  labelId="source-of-joining-label"
                  id="source-of-joining"
                  name="userType"
                  value={formData.userType}
                  onChange={handleInputChange}
                  label="Source of joining"
                  sx={{
                    width: "100%",
                    color: "#FCFCFC", // White font for selected value
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#333" // Border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color when focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color on hover
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#1C1C1C", // Black background for the dropdown
                        color: "#FCFCFC", // White font color for the menu items
                        "& .MuiMenuItem-root": {
                          padding: "10px" // Adjust padding inside menu items
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#333" // Custom color for selected item
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#444" // Darker hover background
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value="Coach">Coach</MenuItem>
                  <MenuItem value="Gym">Gym</MenuItem>
                  <MenuItem value="Company">Company</MenuItem>
                </Select>
              </FormControl>
              {formData.userType !== "Individual" && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: 30 }}
                >
                  <InputLabel
                    id="source-of-joining-name"
                    sx={{ color: "#FCFCFC" }}
                  >
                    {formData.userType} name
                  </InputLabel>
                  <Select
                    labelId="source-of-joining-name"
                    id="source-of-joining"
                    name="referenceId"
                    value={formData.referenceId}
                    onChange={handleInputChange}
                    label="Source of joining"
                    sx={{
                      width: "100%",
                      color: "#FCFCFC",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#333"
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCFCFC"
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCFCFC"
                      },
                      // Limit selected value width with ellipsis
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#1C1C1C",
                          color: "#FCFCFC",
                          maxWidth: "300px", // Set max width for menu items
                          "& .MuiMenuItem-root": {
                            padding: "10px"
                          },
                          "& .Mui-selected": {
                            backgroundColor: "#333"
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#444"
                          }
                        }
                      }
                    }}
                  >
                    {availableOptions?.[formData?.userType.toLowerCase()]
                      ?.length ? (
                      availableOptions?.[formData?.userType.toLowerCase()]?.map(
                        (option) => {
                          return (
                            <MenuItem
                              value={option._id}
                              style={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {
                                option[
                                  formData?.userType?.toLowerCase() + "Name"
                                ]
                              }
                            </MenuItem>
                          );
                        }
                      )
                    ) : (
                      <MenuItem
                        disabled
                        style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        No options available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            </>
          ) : (
            <></>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              loading ||
              Object.values(formErrors).some((error) => error) ||
              !formData.fullName ||
              !formData.displayName ||
              !formData.email ||
              !formData.password
            }
            sx={{
              marginTop: 3.1,
              backgroundColor: "#38B65B",
              cursor: "pointer",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": {
                backgroundColor: "#D7F0DE",
                color: "#9CDBAD"
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Sign up"}
          </Button>
          <Box
            mt={6}
            display="flex"
            gap="20px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Typography userSelect="none">Already Member?</Typography>
            <Link
              to="/login"
              variant="outlined"
              style={{
                color: "#38B65B",
                textDecoration: "none"
              }}
            >
              Sign In
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
