import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery
} from "@mui/material";
import React from "react";
import getInitials from "../../utils/getInitials";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import { DeleteOutline, LinkOutlined } from "@mui/icons-material";
import { ReactComponent as TrophyIcon } from "../../assets/trophy.svg";
import { ReactComponent as StreaksIcon } from "../../assets/streaks.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { ReactComponent as UserIcon } from "../../assets/profile.svg";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { ReactComponent as BillingIcon } from "../../assets/billing.svg";
import { ReactComponent as GroupIcon } from "../../assets/user.svg";
import { ReactComponent as StarIcon } from "../../assets/star.svg";
import { ReactComponent as LogoutIcon } from "../../assets/power.svg";
import { ReactComponent as PremiumIcon } from "../../assets/premiumicon.svg";
import { logoutUser } from "../../features/auth/authSlice";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import SubscriptionComponentMobile from "../Modals/SubscriptionForMobile";

function Profile() {
  const userData = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints
  const navigate = useNavigate();
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    React.useState(false);

  return (
    <Box
      sx={{
        padding: { xs: "0 0 100px 0", md: 0 }
      }}
    >
      <Box width="100%" component="img" src="/images/frame.png" alt="frame" />
      <Box p="0 24px" display="flex" flexDirection="column" gap={6}>
        <Box
          position="relative"
          sx={{
            display: {
              sm: "block",
              md: "flex"
            },
            gap: {
              sm: "140px",
              md: "200px"
            }
          }}
        >
          <Box display="flex" gap="10px" alignItems="center">
            {userData?.profilePic ? (
              <Box
                component="img"
                height={170}
                borderRadius="50%"
                border="3px solid #1C1C1C"
                width={170}
                style={{
                  ...(isMobile && { top: "-45px" })
                }}
                position={"absolute"}
                src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION}/${userData?.profilePic}`}
                alt="avatar"
                bottom="-10px"
              />
            ) : (
              <Avatar
                sx={{
                  ...(isMobile && { top: "-45px" }),
                  width: 170,
                  height: 170,
                  position: "absolute",
                  bottom: "-10px",
                  border: "3px solid #1C1C1C"
                }}
              >
                <Typography color="#FCFCFC" fontSize="30px" fontWeight={400}>
                  {getInitials(userData?.fullName)}
                </Typography>
              </Avatar>
            )}{" "}
          </Box>
          <Box
            pl={2}
            sx={{
              ...(isMobile && { marginTop: "140px" })
            }}
          >
            <Typography color="#FCFCFC" fontSize="30px" fontWeight={500}>
              {userData?.displayName}
            </Typography>
            <Typography color="#FCFCFC" fontSize="16px" fontWeight={600}>
              {userData?.fullName}
            </Typography>
            <Typography color="#9CA3AF" fontSize="16px" fontWeight={400}>
              {userData?.bio}
            </Typography>
            {userData?.url && (
              <Box display="flex" alignItems="center" gap="5px">
                <LinkOutlined
                  color="#368AD0"
                  sx={{ path: { color: "#368AD0" } }}
                />
                <Typography color="#368AD0" fontSize="13px" fontWeight={500}>
                  {userData?.url}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          padding={3}
          height={240}
          border="1px solid #8E8E8E"
          borderRadius={1}
          sx={{ background: "#333333" }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" gap={3}>
              <TrophyIcon />
              <Box>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography color="#FCFCFC" fontSize="24px" fontWeight={500}>
                    425 pts
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    height={30}
                    color="#14142B"
                    sx={{ background: "#FFE1CA" }}
                    padding={"0 10px"}
                    borderRadius="100px"
                  >
                    <StreaksIcon height={14} width={14} />
                    <Typography fontSize="12px"> 20</Typography>
                  </Box>
                </Box>
                <Typography color="#9CA3AF" fontSize="14px" fontWeight={400}>
                  Total points earned
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                // onClick={() => goBack(false)}
                sx={{
                  textTransform: "none",
                  color: "#38B65B",
                  borderColor: "#38B65B",
                  mt: 1, // Reduced margin top
                  gap: "8px",
                  borderRadius: "4px",
                  alignSelf: "flex-end" // Align button to the end horizontally
                }}
              >
                See stats
              </Button>
            </Box>
          </Box>
          <Divider
            sx={{
              background: "#8E8E8E",
              margin: "11px 0"
            }}
          />
          <Box>
            <Typography color="#D1D5DB" fontSize="16px" fontWeight={500}>
              Achievement{" "}
            </Typography>
            <Box display="flex" mt={2} gap={2} alignItems="center">
              <Lvl3Icon height={80} width={80} />
              <Lvl3Icon height={80} width={80} />
              <Lvl3Icon height={80} width={80} />
              <Lvl3Icon height={80} width={80} />
            </Box>
          </Box>
        </Box>
        {isMobile && (
          <Box
            display="flex"
            borderRadius="12px"
            padding={"42px 24px"}
            alignItems={"center"}
            gap="70px"
            sx={{
              background:
                "linear-gradient(123.32deg, #0B7909 1.35%, #184222 83.05%)"
            }}
            onClick={() => setOpenSubscriptionModal(true)}
          >
            <Box>
              <Typography
                color="#38B65B"
                sx={{
                  background: "#FCFCFC",
                  width: "58px",
                  fontSize: "10px",
                  padding: "0 7px",
                  borderRadius: "100px",
                  height: "16px",
                  border: "1px solid #38B65B"
                }}
                mb={2}
                fontWeight={500}
              >
                Level Up
              </Typography>
              <Typography fontWeight={600} fontSize={"18px"}>
                Get 3 Pillars Full Access
              </Typography>
            </Box>
            <PremiumIcon />
            {isMobile && openSubscriptionModal ? (
              <SubscriptionComponentMobile
                open={openSubscriptionModal}
                onClose={() => setOpenSubscriptionModal(false)}
              />
            ) : null}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems={"flex-start"}
        >
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
          >
            <Box>
              <UserIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color="#9CA3AF"
                fontSize="14px"
                fontWeight={400}
                onClick={() => navigate("/edit-profile")}
              >
                Edit Profile
              </Typography>
            </Box>
          </Button>
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
          >
            <Box>
              <LockIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography
                color="#9CA3AF"
                fontSize="14px"
                fontWeight={400}
                onClick={() =>
                  navigate("/update-password", {
                    state: { email: userData?.email }
                  })
                }
              >
                Edit Password
              </Typography>
            </Box>
          </Button>
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
          >
            <Box>
              <BillingIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography color="#9CA3AF" fontSize="14px" fontWeight={400}>
                Manage Billing
              </Typography>
            </Box>
          </Button>
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
          >
            <Box>
              <GroupIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography color="#9CA3AF" fontSize="14px" fontWeight={400}>
                Refer a Friend
              </Typography>
            </Box>
          </Button>
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
          >
            <Box>
              <StarIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography color="#9CA3AF" fontSize="14px" fontWeight={400}>
                Rate Us
              </Typography>
            </Box>
          </Button>
          <Button
            style={{ textTransform: "none", gap: 20, alignItems: "center" }}
            onClick={() => {
              dispatch(logoutUser());
            }}
          >
            <Box>
              <LogoutIcon
                height={24}
                width={24}
                style={{ display: "flex", alignItems: "center" }}
              />
            </Box>
            <Box>
              <Typography color="#9CA3AF" fontSize="14px" fontWeight={400}>
                Log out
              </Typography>
            </Box>
          </Button>
          <Button
            style={{
              textTransform: "none",
              gap: 20,
              textAlign: "center",
              alignItems: "center"
            }}
          >
            <Box>
              <DeleteOutline
                style={{
                  ":hover": {
                    background: "#E94545"
                  },
                  display: "flex",
                  height: 24,
                  width: 24,
                  color: "#E94545" // Use color here to fill the icon
                }}
              />
            </Box>
            <Box>
              <Typography color="#E94545" fontSize="14px" fontWeight={400}>
                Delete Account
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
