import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import modReducer from "../features/mod/modSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mod: modReducer
  }
});
