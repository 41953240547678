import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import CompleteVerification from "./components/Auth/CompleteVerification";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ChangePassword from "./components/Auth/ChangePassword";
import PrivateRoute from "./components/Auth/PrivateRoute";
import NotFound from "./components/NotFound";
import EditProfile from "./components/Modals/EditProfile";
import { Box, CssBaseline } from "@mui/material";
import Mod from "./components/Tabs/Mod";
import Profile from "./components/Tabs/Profile";
import AdminDashboard from "./pages/Dashboard";
import UpdatePassword from "./components/Modals/UpdatePassword";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const DashboardLayout = () => (
  <Box display="flex">
    <CssBaseline />
    <AdminDashboard />
    <Box
      component="main"
      id="dashboard-main"
      sx={{
        flexGrow: 1,
        color: "#FCFCFC",
        background: "#1C1C1C",
        padding: {
          xs: "0 0 115px 0"
        },
        height: "100vh",
        overflow: "auto"
      }}
    >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Mod />} />
        <Route path="/diary" element={<div />} />
        <Route path="/programs" element={<div />} />
        <Route path="/meals" element={<div />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/update-password" element={<UpdatePassword />} />
      </Routes>
    </Box>
  </Box>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/complete-registeration"
          element={<CompleteVerification />}
        />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ChangePassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
