import { Typography, Box } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      sx={{ overflow: "hidden" }}
    >
      <Box
        sx={{
          width: { xs: 0, md: "50%" },
          height: "100vh",
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: { xs: 0, md: "100%" },
            height: "100vh",
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingTop={20}
        paddingBottom={4}
        paddingLeft={10}
        sx={{
          paddingRight: { xs: 5, md: 13, lg: 30 },
          paddingLeft: { xs: 5, md: 10 }
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection="column"
          height="100%"
        >
          <Box>
            <Typography fontSize="30px" fontWeight="bold" gutterBottom>
              We couldn't find anything here!
            </Typography>
            <Typography color="#6B7280" fontSize="16px" gutterBottom>
              Please check the URL or try searching for what you need.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
