import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as TPillars } from "../assets/mainlogo.svg";
import { ReactComponent as DiaryIcon } from "../assets/diary.svg";
import { ReactComponent as ModIcon } from "../assets/mod.svg";
import { ReactComponent as MealsIcon } from "../assets/meals.svg";
import { ReactComponent as ProfileIcon } from "../assets/profile.svg";
import { ReactComponent as ProgramIcon } from "../assets/programs.svg";
import { ReactComponent as PremiumIcon } from "../assets/premium.svg";
import {
  Button,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import SubscriptionModal from "../components/Modals/SubscriptionModal";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "92px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(4, 1),
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth - 20,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "visible"
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "visible"
    }
  })
}));

const AdminDashboard = () => {
  const open = true;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    React.useState(false);
  const menuItems = [
    { text: "Mod", icon: <ModIcon />, path: "/" },
    { text: "Diary", icon: <DiaryIcon />, path: "/diary" },
    { text: "Programs", icon: <ProgramIcon />, path: "/programs" },
    { text: "Meals", icon: <MealsIcon />, path: "/meals" },
    { text: "Profile", icon: <ProfileIcon />, path: "/profile" }
  ];

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ".MuiDrawer-paper": {
            display: "flex",
            justifyContent: "space-between",
            background: "#1C1C1C",
            color: "#9CA3AF"
          },
          display: { xs: "none", sm: "block" }
        }}
      >
        <Box>
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              component="div"
              sx={{
                width: { xs: "60%", sm: "80%" },
                height: "auto"
              }}
            >
              <TPillars style={{ width: "100%", height: "auto" }} />
            </Box>
          </DrawerHeader>
          <Divider />
          <List style={{ marginTop: "20px" }}>
            {menuItems.map(({ text, icon, path }) => (
              <ListItem key={text} sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => navigate(path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ...(location.pathname === path && {
                      background: "#1F2937",
                      borderRadius: "4px"
                    })
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      ...(location.pathname === path
                        ? !["Meals", "Profile"].includes(text)
                          ? {
                              "& svg path": {
                                fill: "#38B65B"
                              }
                            }
                          : {
                              "& svg path": {
                                stroke: "#38B65B",
                                fill: "none" // Optional: Set fill to none for these two
                              }
                            }
                        : {})
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{
                      opacity: open ? 1 : 0,
                      ...(location.pathname === path && {
                        color: "#38B65B"
                      })
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          margin="0 16px 40px 16px"
          alignItems={"center"}
          justifyContent={"center"}
          display="flex"
          flexDirection="column"
          sx={{
            background: "#333333",
            padding: "20px",
            color: "#9CA3AF",
            borderRadius: "4px",
            display: { xs: "none", sm: "flex" }
          }}
        >
          <PremiumIcon />
          <Typography mt={2} fontSize="16px" fontWeight={600} color="#FCFCFC">
            3 Pillar full Access
          </Typography>
          <Typography fontSize="12px" fontWeight={400} color="#FCFCFC">
            Get all benefits
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            style={{
              textTransform: "none",
              color: "#38B65B",
              borderColor: "#38B65B",
              marginTop: "10px"
            }}
            onClick={() => setOpenSubscriptionModal(true)}
          >
            View Plans
          </Button>
        </Box>
      </Drawer>
      <SubscriptionModal
        open={openSubscriptionModal}
        onClose={() => setOpenSubscriptionModal(false)}
      />
      {isMobile && (
        <BottomNavigation
          showLabels
          sx={{
            height: "80px",
            paddingBottom: "20px",
            display: { xs: "flex", sm: "none" },
            position: "fixed",
            bottom: 0,
            marginBottom: 0,
            width: "100%",
            zIndex: 1000,
            backgroundColor: "#333333",
            svg: { path: { fill: "#9CA3AF" } }
          }}
          value={location.pathname}
          onChange={(event, newValue) => {
            navigate(newValue);
          }}
        >
          {menuItems.map(({ text, icon, path }) => (
            <BottomNavigationAction
              key={text}
              label={text}
              icon={icon}
              value={path}
              sx={{
                "&.MuiButtonBase-root ": {
                  gap: "4px",
                  ...(location.pathname === path && {
                    svg: { path: { fill: "#38B65B" } }
                  })
                },
                color: "#9CA3AF",
                ...(location.pathname === path && {
                  color: "#38B65B !important"
                })
              }}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
};

export default AdminDashboard;
