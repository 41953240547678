import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addNewMod } from "../../features/mod/modSlice";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import ThankyouModal from "./ThankYouModal";
import { useTheme } from "@emotion/react";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(4, 0, 0, 0)
  },
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(1, 0, 3, 3)
  }
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-15px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    ".Mui-disabled": {
      "-webkit-text-fill-color": "#6A7178"
    },
    ".MuiInputBase-input": {
      color: "#FCFCFC"
    },
    "& fieldset": {
      borderColor: "#333333"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333333"
    }
  }
}));

const AddMod = ({ isAddingMeal, setIsAddingMeal }) => {
  const [formData, setFormData] = useState({
    title: "",
    generalText: "",
    profilePic: null,
    fruitsVeggies: [{ name: "", quantity: "", unit: "gram" }],
    proteins: [{ name: "", quantity: "", unit: "gram" }],
    others: [{ name: "", quantity: "", unit: "gram" }]
  });
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openThanksModal, setOpenThanksModal] = useState(false);
  const dispatch = useDispatch();
  // Handle input change for title, generalText, and other inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle dynamic field changes for fruits, proteins, and others
  const handleDynamicInputChange = (index, e, type) => {
    const { name, value } = e.target;
    const updatedArray = formData[type].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };

  // Handle adding new input for fruitsVeggies, proteins, or others
  const handleAddMore = (type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: [
        ...prev[type],
        { name: "", quantity: "", unit: type === "others" ? "" : "gram" }
      ]
    }));
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    setFormData((prev) => ({
      ...prev,
      profilePic: file
    }));
  };


  const handleSubmit = async () => {
    try {
      setLoading(true);
      const mainData = new FormData();

      if (!formData.title || !formData.profilePic) {
        setSnackbarMessage(
          `Meal ${
            !formData.title ? "Title" : !formData.profilePic ? "Image" : ""
          } is required`
        );
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }
      if (
        formData.fruitsVeggies.length &&
        (!formData.fruitsVeggies[0].name || !formData.fruitsVeggies[0].quantity)
      ) {
        setSnackbarMessage("Provide atleast one fruit and veggies details.");
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      // Prepare data object
      const data = {
        title: formData.title,
        fruitsVeggies: [],
        otherIngredients: [],
        protein: [],
        generalText: formData.generalText || "",
        status: "pending"
      };

      // Process fruitsVeggies array
      if (
        formData.fruitsVeggies.length > 0 &&
        formData.fruitsVeggies[0].name !== ""
      ) {
        formData.fruitsVeggies.forEach((item) => {
          if (item.name && item.quantity) {
            data.fruitsVeggies.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process other ingredients array
      if (formData.others.length > 0 && formData.others[0].name !== "") {
        formData.others.forEach((item) => {
          if (item.name && item.quantity) {
            data.otherIngredients.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process proteins array
      if (formData.proteins.length > 0 && formData.proteins[0].name !== "") {
        formData.proteins.forEach((item) => {
          if (item.name && item.quantity) {
            data.protein.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Convert data to JSON string
      const jsonString = JSON.stringify(data);

      // Encode JSON string to Base64 (btoa is used for encoding)
      const base64EncodedData = btoa(jsonString);

      // Create a new FormData object to send the base64 encoded data
      mainData.append("data", base64EncodedData);

      if (formData.profilePic) {
        mainData.append("mealImage", formData.profilePic);
      }
      const { payload } = await dispatch(addNewMod(mainData));
      setLoading(false);
      if (!payload.error) {
        setOpenThanksModal(true);
      } else {
        setSnackbarMessage(
          payload.error || "Failed to add meal. Please try again."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to add meal. Please try again.");
      setOpenSnackbar(true);
    }
  };
  const handleDelete = (index, type) => {
    const updatedArray = formData[type].filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints

  useEffect(() => {
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <Box
        display={"flex"}
        alignItems="center"
        gap="20px"
        padding={isMobile ? 2 : 0}
      >
        <IconButton onClick={() => setIsAddingMeal(false)} sx={{ padding: 0 }}>
          <CloseIcon sx={{ height: "28px", color: "#FCFCFC", width: "28px" }} />
        </IconButton>
        <Typography
          color="#FCFCFC"
          fontSize={isMobile ? "24px" : "30px"}
          fontWeight={isMobile ? 600 : 500}
        >
          Submit your MOD
        </Typography>
      </Box>
      <Box
        component="form"
        margin={isMobile ? "20px" : "24px 40px 0 0"}
        display={!isMobile ? "flex" : "block"}
      >
        <Box>
          {formData.profilePic ? (
            <Avatar
              sx={{
                width: isMobile ? "100%" : 500,
                height: isMobile ? 200 : 500,
                bgcolor: "#f0f0f0",
                color: "#bdbdbd",
                borderRadius: "10px",
                mb: 2
              }}
              src={URL.createObjectURL(formData.profilePic)}
            />
          ) : (
            <Box
              onClick={() => document.getElementById("upload-input").click()}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: isMobile ? "100%" : 500,
                height: isMobile ? 200 : 500,
                border: "1px dashed #D1D5DB",
                borderRadius: "10px",
                position: "relative",
                cursor: "pointer",
                bgcolor: "#333333"
              }}
            >
              <CameraIcon width={67} height={60} color="#D1D5DB" />
              <Typography
                fontSize="12px"
                fontWeight="bold"
                color="#FCFCFC"
                mt={1}
              >
                Tap to upload
              </Typography>
              <Typography fontSize="10px" color="#FCFCFC">
                (Supported file PNG, JPEG)
              </Typography>
              <input
                id="upload-input"
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          )}
        </Box>
        <Box width={"100%"}>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="title">Title</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="title"
              placeholder="Enter the title of the meal"
              value={formData.title}
              onChange={handleInputChange}
              required
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel htmlFor="fruitsvegies">
              Fruits & Veggies used in your meal
            </StyledInputLabel>
            {formData.fruitsVeggies.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="Enter fruit or veggie"
                  value={item.name}
                  margin="normal"
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "fruitsVeggies")
                  }
                  required
                />
                <StyledTextField
                  type="number"
                  name="quantity"
                  margin="normal"
                  placeholder="No."
                  value={item.quantity}
                  slotProps={{ htmlInput: { min: 1 } }}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "fruitsVeggies")
                  }
                  sx={{
                    marginTop: "16px",
                    width: { xs: "30%", md: "25%" }
                  }}
                />
                <StyledTextField
                  margin="normal"
                  placeholder="Unit"
                  value="Gram"
                  disabled
                  sx={{
                    marginTop: "16px",
                    borderRadius: "4px",
                    width: { xs: "30%", md: "25%" },
                    background: "#333333"
                  }}
                />
                {formData.fruitsVeggies.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "fruitsVeggies")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#E94545",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("fruitsVeggies")}
              sx={{
                textTransform: "none",
                color: "#38B65B",
                borderColor: "#38B65B",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#38B65B"
                  }
                }}
              />
              Add more
            </Button>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel htmlFor="protein">Protein</StyledInputLabel>
            {formData.proteins.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="Enter protein"
                  value={item.name}
                  margin="normal"
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "proteins")
                  }
                  required
                />
                <StyledTextField
                  type="number"
                  name="quantity"
                  margin="normal"
                  slotProps={{ htmlInput: { min: 1 } }}
                  placeholder="No."
                  value={item.quantity}
                  onChange={(e) =>
                    handleDynamicInputChange(index, e, "proteins")
                  }
                  sx={{
                    marginTop: "16px",
                    width: { xs: "30%", md: "25%" }
                  }}
                />
                <StyledTextField
                  margin="normal"
                  placeholder="Unit"
                  value="Gram"
                  disabled
                  sx={{
                    marginTop: "16px",
                    borderRadius: "4px",
                    width: { xs: "30%", md: "25%" },
                    background: "#333333"
                  }}
                />
                {formData.proteins.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "proteins")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#E94545",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("proteins")}
              sx={{
                textTransform: "none",
                color: "#38B65B",
                borderColor: "#38B65B",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#38B65B"
                  }
                }}
              />
              Add more
            </Button>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            style={{
              marginBottom: 0,
              marginTop: "30px"
            }}
          >
            <StyledInputLabel htmlFor="other">
              Other ingredients
            </StyledInputLabel>
            {formData.others.map((item, index) => (
              <Box display="flex" alignItems="center" gap="20px">
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  placeholder="Enter other ingredients"
                  value={item.name}
                  margin="normal"
                  onChange={(e) => handleDynamicInputChange(index, e, "others")}
                  required
                />
                <StyledTextField
                  type="number"
                  name="quantity"
                  margin="normal"
                  placeholder="No."
                  value={item.quantity}
                  slotProps={{ htmlInput: { min: 1 } }}
                  onChange={(e) => handleDynamicInputChange(index, e, "others")}
                  sx={{ marginTop: "16px", width: { xs: "30%", md: "25%" } }}
                />
                <FormControl
                  sx={{
                    width: { xs: "40%", md: "25%" },
                    marginTop: "8px",
                    svg: {
                      fill: "#FCFCFC"
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#333333"
                      },
                      "&:hover fieldset": {
                        borderColor: "#FCFCFC"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FCFCFC"
                      }
                    }
                  }}
                >
                  <Select
                    labelId="unit-label"
                    name="unit"
                    value={item.unit}
                    sx={{
                      color: "#FCFCFC",
                      marginTop: 0, // Remove any margin
                      paddingTop: 0 // Remove any padding
                    }}
                    displayEmpty
                    onChange={(e) =>
                      handleDynamicInputChange(index, e, "others")
                    }
                  >
                    <MenuItem value="gram">Gram</MenuItem>
                    <MenuItem value="pounds">Pounds</MenuItem>
                    <MenuItem value="cup">Cup</MenuItem>
                    <MenuItem value="pint">Pint</MenuItem>
                    <MenuItem value="Tps">Tps</MenuItem>
                    <MenuItem value="tps">tps</MenuItem>
                  </Select>
                </FormControl>
                {formData.others.length > 1 && (
                  <IconButton
                    onClick={() => handleDelete(index, "others")}
                    sx={{
                      fontSize: "16px",
                      borderRadius: "4px",
                      background: "#E94545",
                      svg: {
                        path: {
                          fill: "#FCFCFC"
                        }
                      },
                      "&:hover": {
                        background: "#FF4000"
                      }
                    }}
                  >
                    <Delete height={24} width={24} />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAddMore("others")}
              sx={{
                textTransform: "none",
                color: "#38B65B",
                borderColor: "#38B65B",
                marginTop: "12px",
                gap: "8px",
                borderRadius: "4px"
              }}
            >
              <Add
                sx={{
                  path: {
                    fill: "#38B65B"
                  }
                }}
              />
              Add more
            </Button>
          </StyledTextFieldContainer>
          <Box m={3}></Box>
          <StyledTextFieldContainer style={{ marginTop: "30px" }}>
            <StyledInputLabel htmlFor="generalText">
              General Text
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="generalText"
              placeholder="Enter general text"
              value={formData.generalText}
              onChange={handleInputChange}
              required
              multiline
              rows={5}
              margin="normal"
            />
          </StyledTextFieldContainer>{" "}
          <Box
            ml={!isMobile ? 2.8 : 0}
            mt={isMobile ? 3 : 0}
            gap={2}
            display="flex"
          >
            <Button
              fullWidth
              onClick={handleSubmit}
              disabled={
                !formData.title ||
                !formData.profilePic ||
                (formData.fruitsVeggies.length &&
                  (!formData.fruitsVeggies[0].name ||
                    !formData.fruitsVeggies[0].quantity))
              }
              sx={{
                textTransform: "none",
                color: "#fcfcfc",
                padding: "12px 20.5px",
                background: "#38B65B",
                fontWeight: 700,
                "&:disabled": {
                  backgroundColor: "#D7F0DE",
                  color: "#9CDBAD",
                  pointerEvents: "none"
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="error">
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
        {openThanksModal && (
          <ThankyouModal
            openThanksModal={openThanksModal}
            handleCloseThanksModal={setOpenThanksModal}
            goBack={setIsAddingMeal}
          />
        )}
      </Box>
    </>
  );
};
export default AddMod;
