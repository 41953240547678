import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCurrentUser } from "../../features/auth/authSlice";
import {
  selectAuthLoading,
  selectCurrentUser
} from "../../features/auth/authSelectors";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { completeProfile } from "../../api/userApi";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC",
    ":disabled": {
      backgroundColor: "#D7F0DE",
      color: "#FCFCFC",
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    },
    "&.Mui-disabled": {
      "& input": {
        color: "red",
        cursor: "not-allowed"
      },
      "& fieldset": {
        borderColor: "#333333"
      }
    }
  }
}));

const EditProfile = () => {
  const userData = useSelector(selectCurrentUser);
  const [formData, setFormData] = useState({
    fullName: userData.fullName || "",
    displayName: userData.displayName || "",
    profilePic: null,
    email: userData.email || "",
    bio: userData.bio || "",
    url: userData.url || ""
  });
  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    bio: false,
    url: false
  });
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "bio") {
      setFormErrors({
        ...formErrors,
        bio: !(value.length < 50)
      });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleEditClick = () => {
    // Programmatically click the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (formData.isJoiningThroughSource) {
      setFormData((prevState) => ({
        ...prevState,
        referenceId: ""
      }));
    }
  }, [formData.userType, formData.isJoiningThroughSource]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };

  const handleUpdate = async (e) => {
    const mainData = new FormData();
    mainData.append("fullName", formData.fullName);
    mainData.append("displayName", formData.displayName);
    mainData.append("bio", formData.bio);
    mainData.append("url", formData.url);
    if (formData.profilePic) {
      mainData.append("profilePic", formData.profilePic);
    }
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    try {
      const data = await completeProfile(mainData, userData?.userId);
      if (data) {
        setSnackbarMessage("Profile updated successfully.");
        setOpenSnackbar(true);
        dispatch(fetchCurrentUser());
      } else {
        setSnackbarMessage("Profile update failed.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Profile update failed.");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap={3} p="32px 0 0 20px">
        <IconButton onClick={() => navigate("/profile")}>
          <ChevronLeft
            sx={{ height: "35px", width: "35px", fill: "white" }}
            height={35}
            width={35}
          />
        </IconButton>
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="24px"
        >
          Edit Profile
        </Typography>

        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          width: { xs: "100%", md: "60%" },
          paddingTop: { xs: 2, md: 4 },
          paddingRight: { xs: 2, md: 13, lg: 20 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 10 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <Box
          component={"form"}
          sx={{
            ...(matches && { marginBottom: { xs: 9 } })
          }}
          onSubmit={handleUpdate}
        >
          <input
            accept="image/*"
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              ...(matches && { justifyContent: "center" })
            }}
            display="flex"
            gap="50px"
            alignItems="center"
          >
            {formData.profilePic ? (
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <label htmlFor="profile-pic-upload">
                  <Avatar
                    alt="Profile Pic"
                    src={URL.createObjectURL(formData.profilePic)}
                    sx={{
                      width: 124,
                      height: 124,
                      marginTop: 2,
                      border: "1px solid #38B65B"
                    }}
                  />
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      position: "absolute",
                      bottom: 10, // Adjust this value to move the icon slightly outside the avatar if desired
                      right: 5, // Adjust this value to move the icon slightly outside the avatar if desired
                      backgroundColor: "#38B65B",
                      borderRadius: "50%",
                      height: 20,
                      width: 20,
                      border: "1px solid #38B65B",
                      fontSize: 24, // Adjust the size as needed
                      padding: "2px",
                      svg: {
                        path: {
                          fill: "#fff"
                        }
                      }
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </label>
              </Box>
            ) : (
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <label htmlFor="profile-pic-upload">
                  <Avatar
                    style={{
                      borderRadius: "48%",
                      backgroundColor: "#ccc",
                      width: 124,
                      height: 124,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 16,
                      cursor: "pointer",
                      border: "1px solid #38B65B"
                    }}
                    src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION}/${userData?.profilePic}`}
                  />
                  {/* Edit icon positioned at the bottom-right corner of the avatar */}
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      position: "absolute",
                      bottom: 10, // Adjust this value to move the icon slightly outside the avatar if desired
                      right: 5, // Adjust this value to move the icon slightly outside the avatar if desired
                      backgroundColor: "#38B65B",
                      borderRadius: "50%",
                      height: 20,
                      width: 20,
                      border: "1px solid #38B65B",
                      fontSize: 24, // Adjust the size as needed
                      padding: "2px",
                      svg: {
                        path: {
                          fill: "#fff"
                        }
                      }
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </label>
              </Box>
            )}
          </Box>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="fullName"
              placeholder="Enter your Full name"
              value={formData.fullName}
              onChange={handleInputChange}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="displayName">
              Username (This will be displayed in app)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="displayName"
              sx={{ marginTop: "20px" }}
              placeholder="Enter your Username"
              value={formData.displayName}
              onChange={handleInputChange}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="bio">
              Bio (50 character text)
            </StyledInputLabel>
            <StyledTextField
              sx={{
                "& .MuiInputBase-root": {
                  color: "#FCFCFC"
                }
              }}
              fullWidth
              variant="outlined"
              name="bio"
              placeholder="Enter your bio (optional)"
              value={formData.bio}
              multiline
              rows={4}
              onChange={handleInputChange}
              margin="normal"
              error={formErrors.bio}
              helperText={
                formErrors.bio && "Bio should be less than 50 characters"
              }
            />
            <Typography
              variant="caption"
              sx={{
                color: formData.bio.length > 50 ? "red" : "#D1D5DB",
                position: "absolute",
                fontSize: "15px",
                right: 10,
                bottom: 10
              }}
            >
              {formData.bio.length}/50
            </Typography>
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">Email Address</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="email"
              type="email"
              disabled
              placeholder="Enter your email address"
              value={formData.email}
              onChange={() => {}}
              margin="normal"
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="url">
              URL (link to their website, social media, etc.)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              sx={{ marginTop: "20px" }}
              variant="outlined"
              name="url"
              placeholder="Enter URL (optional)"
              value={formData.url}
              onChange={handleInputChange}
              margin="normal"
            />
          </StyledTextFieldContainer>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              marginTop: 3.1,
              backgroundColor: "#38B65B",
              cursor: "pointer",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": {
                backgroundColor: "#D7F0DE",
                color: "#9CDBAD"
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditProfile;
